




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { ModuleTreeRecord } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import { boldSearchKeywords } from '@/utils/search.util';
import { ModuleTree } from '@/store/modules/module-tree/types/module-tree.types';
import { TreeSelectStruct } from '@/interfaces/tree-select-struct.interface';
import Treeselect from '@riophae/vue-treeselect';
import { uniqBy } from 'lodash';

@Component({
  components: { Treeselect, BaseLoading }
})
export default class ModuleSearchComponent extends Vue {
  $refs!: {
    moduleSearchDropdown: any;
  };

  @Prop() public moduleTreeRecord!: ModuleTreeRecord;

  public isLoading: boolean = false;
  public selectedItem: ModuleTreeRecord | null = null;
  public moduleName: string = '';
  public filteredModules: ModuleTree[] = [];
  public moduleTreeNewRef: ModuleTree[] = [];

  get filteredItemList() {
    return this.modules;
  }

  @Action('rolesAndPermissions/getModules')
  public getModules!: (moduleName?: string) => Promise<ModuleTreeRecord>;

  @Action('moduleTree/getModuleTree')
  public getModuleTree!: () => Promise<ModuleTree[]>;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.apiState.getModules
  )
  public getModulesState!: ApiState;

  @State(({ moduleTree }: RootState) => moduleTree.moduleTree)
  public moduleTree!: ModuleTree[];

  @State(
    ({ moduleTree }: RootState) => moduleTree.apiState.getModuleTree.success
  )
  public getModuleTreeSuccessState!: boolean;

  @State(({ rolesAndPermissions }: RootState) => rolesAndPermissions.modules)
  private modules!: ModuleTreeRecord[];

  private toggleDropdown() {
    if (!this.selectedItem && !this.$refs.moduleSearchDropdown.isActive) {
      this.$refs.moduleSearchDropdown.toggle();
    }
  }

  private mounted(): void {
    this.getModuleList();
    this.getModuleTree();
  }

  private onInputFocus(): void {
    this.getModuleList();
  }

  private getModuleList(): void {
    this.getModules(this.moduleName);
    this.isLoading = true;
  }

  private onEditItem(): void {
    this.moduleName = '';
    this.selectedItem = null;
    this.getModuleList();
  }

  private moduleLabel(moduleName: string): string {
    return boldSearchKeywords(moduleName, this.moduleName);
  }

  get moduleTreeForVueTreeselect() {
    if (this.moduleTree) {
      return this.deserializeTreeObjectForVueTreeselect(this.moduleTreeNewRef);
    }
  }

  private deserializeTreeObjectForVueTreeselect(
    modules: ModuleTree[]
  ): TreeSelectStruct[] {
    const treeSelectStruct: TreeSelectStruct[] = [];
    for (const module of modules) {
      treeSelectStruct.push({
        id: module.id,
        label: module.label,
        children:
          module.submodules.length > 0
            ? this.deserializeTreeObjectForVueTreeselect(module.submodules)
            : undefined
      } as TreeSelectStruct);
    }

    return treeSelectStruct;
  }

  /**
   * This is a duplicate function from ViewRolePermissionsComponent. The reason
   * it has not been turned into a pure function and defined in a utility file
   * is that it transforms data defined in *this* component, making it anything
   * but pure.
   *
   * It is being used to serve as an override for vue-treeselect's own fuzzy
   * search logic. Vue-treeselect uses a very eager search algorithm, leading to
   * odd/incorrect search results being returned.
   * @param query
   * @private
   */
  private filterModuleTree(query: string): void {
    if (!query) {
      this.filteredModules = [];
      this.moduleTreeNewRef = JSON.parse(JSON.stringify(this.moduleTree));
    } else {
      this.moduleTreeNewRef = JSON.parse(JSON.stringify(this.moduleTree));
      this.filteredModules = [];
      this.moduleTreeNewRef = uniqBy(
        this.searchModuleTree(
          this.moduleTreeNewRef,
          query.toLowerCase().trim()
        ),
        'label'
      );
    }
  }

  private searchModuleTree(
    moduleTree: ModuleTree[],
    query: string,
    parentModule?: ModuleTree
  ): ModuleTree[] {
    for (const module of moduleTree) {
      const rootModule = module.level === 0 ? module : parentModule;
      if (module.label?.toLowerCase().trim().includes(query)) {
        if (rootModule) {
          this.filteredModules.push(rootModule);
        }
      } else {
        this.searchModuleTree(module.submodules, query, rootModule);
      }
    }

    return this.filteredModules;
  }

  @Debounce(500)
  @Watch('moduleName')
  private onModuleNameSearch(value: string): void {
    this.moduleName = value;
    this.getModuleList();
  }

  @Watch('selectedItem')
  private onSelectItems(item: number): void {
    const selectedItem = this.modules?.find((module) => module.id === item);
    this.$emit('selectedModule', selectedItem);
  }

  @Watch('getModulesState')
  private onGetModulesState(state: ApiState): void {
    if (state.loading) {
      this.isLoading = true;
    } else {
      this.isLoading = false;
    }
  }

  @Watch('getModuleTreeSuccessState')
  private onGetModuleTreeSuccessState(state: boolean): void {
    if (state) {
      if (this.moduleTree) {
        this.moduleTreeNewRef = JSON.parse(JSON.stringify(this.moduleTree));
      }
    }
  }
}
